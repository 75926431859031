/**
 * Custom icon list
 * All icons are loaded here for easy management
 * @see https://vue.ant.design/components/icon/#Custom-Font-Icon
 *
 * 自定义图标加载表
 * 所有图标均从这里加载，方便管理
 */
import msg from '@/assets/icons/icon_msg.svg?inline';
import student from '@/assets/icons/icon_student.svg?inline';
import personalCenter from '@/assets/icons/icon_personalcenter.svg?inline';
import wukong from '@/assets/icons/service_icon_wukong.svg?inline';
import organization from '@/assets/icons/icon_zuzhi.svg?inline';
import task from '@/assets/newImage/task.svg?inline';
import newstudent from '@/assets/newImage/student.svg?inline';
import chat from '@/assets/newImage/chat.svg?inline';
import schedule from '@/assets/newImage/schedule.svg?inline';
import manage from '@/assets/newImage/manage.svg?inline';
import task_a from '@/assets/newImage/task_a.svg?inline';
import newstudent_a from '@/assets/newImage/student_a.svg?inline';
import chat_a from '@/assets/newImage/chat_a.svg?inline';
import schedule_a from '@/assets/newImage/schedule_a.svg?inline';
import manage_a from '@/assets/newImage/manage_a.svg?inline';
import customer_a from '@/assets/newImage/customer_a.svg?inline';
import customer from '@/assets/newImage/customer.svg?inline';
import homepage_a from '@/assets/newImage/homepage_a.svg?inline'
import homepage from '@/assets/newImage/homepage.svg?inline'
import statistics_a from '@/assets/newImage/statistics_a.svg?inline'
import statistics from '@/assets/newImage/statistics.svg?inline'
import order from '@/assets/newImage/order.svg?inline'
import order_a from '@/assets/newImage/order_a.svg?inline'
import setting from '@/assets/newImage/setting.svg?inline'
import setting_a from '@/assets/newImage/setting_a.svg?inline'

export {
  newstudent,
  chat,
  schedule,
  manage,
  task,
  msg,
  personalCenter,
  student,
  wukong,
  organization,
  task_a,
  newstudent_a,
  chat_a,
  schedule_a,
  manage_a,
  customer_a,
  customer,
  homepage_a,
  homepage,
  statistics_a,
  statistics,
  order_a,
  order,
  setting,
  setting_a
};

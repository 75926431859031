/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable func-style */
import * as R from 'ramda';
import storage from 'store';
import { ACCESS_TOKEN, REFRESH_ACCESS_TOKEN } from '@/store/mutation-types';
import moment from 'moment';

export function timeFix() {
  const time = new Date();
  const hour = time.getHours();
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好';
}

export function welcome() {
  const arr = ['休息一会儿吧', '我猜你可能累了'];
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents');
  event.initEvent('resize', true, true);
  event.eventType = 'message';
  window.dispatchEvent(event);
}

export function handleScrollHeader(callback) {
  let timer = 0;

  let beforeScrollTop = window.pageYOffset;
  callback = callback || function () {};
  window.addEventListener(
    'scroll',
    () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        let direction = 'up';
        const afterScrollTop = window.pageYOffset;
        const delta = afterScrollTop - beforeScrollTop;
        if (delta === 0) {
          return false;
        }
        direction = delta > 0 ? 'down' : 'up';
        callback(direction);
        beforeScrollTop = afterScrollTop;
      }, 50);
    },
    false,
  );
}

export function isIE() {
  const bw = window.navigator.userAgent;
  const compare = (s) => bw.indexOf(s) >= 0;
  const ie11 = (() => 'ActiveXObject' in window)();
  return compare('MSIE') || ie11;
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return;
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id));
  }, timeout);
}
/**
 deepClone
 */
export function deepClone(obj) {
  if (obj === null || !obj) return obj;
  if (Object.prototype.toString.call(obj) === '[object Object]') {
    const target = {};
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        target[key] = deepClone(obj[key]);
      } else {
        target[key] = obj[key];
      }
    });
    return target;
  }
  if (Array.isArray(obj)) {
    const arr = [];
    obj.forEach((item, index) => {
      if (item && typeof item === 'object') {
        arr[index] = deepClone(item);
      } else {
        arr[index] = item;
      }
    });
    return arr;
  }
}
// 用名称过滤
export function nameFilterGroup(name, arr) {
  // 获得名称
  const newArr = [];
  for (let i = 0; i < arr.length; i++) {
    const groupName = getGroupName(arr[i]);
    const index = groupName.indexOf(name);
    if (index > -1) {
      newArr.push(arr[i]);
    }
  }
  return newArr;
}
// 用名称过滤接入数据
export function nameFilterAccessGroup(name, arr) {
  // 获得名称
  const newArr = [];
  for (let i = 0; i < arr.length; i++) {
    const { groupName } = arr[i];
    const index = groupName.indexOf(name);
    if (index > -1) {
      newArr.push(arr[i]);
    }
  }
  return newArr;
}
// 获取名称
export function getGroupName(data) {
  const groupName = data.groupProfile.groupCustomField.filter((item) => {
    return item.key === 'groupName';
  });
  return (groupName && groupName.length > 0 && groupName[0].value) || data.groupProfile.name;
}

// 过滤数据
export function getArrEqual(arr1, arr2) {
  const newArr = [];
  for (let i = 0; i < arr2.length; i++) {
    for (let j = 0; j < arr1.length; j++) {
      if (arr1[j].groupID === arr2[i].groupID) {
        newArr.push(Object.assign(arr2[i], arr1[j]));
      }
    }
  }
  return newArr;
}

export function getArrEqualXWK(arr1, arr2) {
  const newArr = [];
  for (let i = 0; i < arr2.length; i++) {
    for (let j = 0; j < arr1.length; j++) {
      if (arr1[j].groupID === arr2[i].groupProfile.groupID) {
        newArr.push(Object.assign(arr2[i], arr1[j]));
      }
    }
  }
  return newArr;
}

// 判断是两个数组id是否不一样
export function getArrIsSame(arr1, arr2) {
  // arr1新数据 arr2旧数据
  const arr1Ids = getArrID(arr1);

  const arr2Ids = getArrID(arr2);

  // 存在差值 表示不一样
  const res = R.difference(arr1Ids, arr2Ids);
  return R.isEmpty(res);
}

export function getArrID(arr) {
  let newArr = [];
  if (arr && arr.length > 0) {
    newArr = R.map((x) => x.groupId)(arr);
  }
  return newArr;
}

// 判断是两个数组id是否不一样
export function getArrIsSameXWK(arr1, arr2) {
  // arr1新数据 arr2旧数据
  const arr1Ids = getArrIDXWK(arr1);

  const arr2Ids = getArrIDXWK(arr2);

  // 存在差值 表示不一样
  const res = R.difference(arr1Ids, arr2Ids);
  return R.isEmpty(res);
}

export function getArrIDXWK(arr) {
  let newArr = [];
  if (arr && arr.length > 0) {
    newArr = R.map((x) => x.groupProfile.groupID)(arr);
  }
  return newArr;
}
// 下拉框 根据value获取label
export function getDdlLabel(ddlOptions, value) {
  if (!ddlOptions || ddlOptions.length < 1 || !value) {
    return '';
  }
  for (let i = 0; i < ddlOptions.length; i++) {
    if (ddlOptions[i].value == value) {
      return ddlOptions[i].label;
    }
  }
}
// 获取操作人
export function extractOperator(text) {
  if (!text) return '';
  return text.substring(text.indexOf('(') + 1, text.indexOf(')'));
}

// 复制到剪贴板
export const handleCopy = async (text) => {
  await navigator.clipboard.writeText(text);
};
// 获取crm地址
export const getCRMAddress = (urlHref) => {
  const address = window.location.href;
  let crmAddress = 'https://crm.wukongedu.net';
  if (address.includes('dev') || address?.includes('localhost') || /[\d]+\.[\d]+\.[\d]+\.[\d]+:[\d]+/.test(address)) {
    crmAddress = 'https://crm-dev.wukongedu.net';
  } else if (address.includes('test')) {
    crmAddress = 'https://crm-test.wukongedu.net';
  }
  const externalToken = storage.get(ACCESS_TOKEN);
  const externalRefreshToken = storage.get(REFRESH_ACCESS_TOKEN);
  const url = `${crmAddress}/external?externalToken=${externalToken}&externalRefreshToken=${externalRefreshToken}&redirect=${urlHref}&externalSource=CA-STUDIO`;
  console.log(url);
  window.open(url);
};

/**
 * 格式化数据，value为空 显示 '-'
 * @param {string} value
 * @param {string} unit 单位
 * @returns {string|*}
 */
export function formatValue(value, unit = '') {
  const rules = ['', null, undefined];

  if (rules.some((r) => r === value)) {
    return '-';
  }
  return value + unit;
}
// 新开页
export const openNewTab = (path) => {
  window.open(`${window.location.protocol}//${window.location.host}${path}`);
};

export const toUnderLine = (str) => {
  if (!str) return;
  return str.replace(/([A-Z])/g, '_$1').toLowerCase();
};

/**
 * 获取http header 文件名
 */
export const getFilenameFromText = (disposition, defaultName) => {
  let filename = defaultName;
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition);
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, '');
  }
  return filename;
};

// 获取一个base64
export const getBase64Size = (base64) => {
  if (base64) {
    // 获取base64图片byte大小
    const equalIndex = base64.indexOf('='); // 获取=号下标
    if (equalIndex > 0) {
      const str = base64.substring(0, equalIndex); // 去除=号
      const strLength = str.length;
      const fileLength = strLength - (strLength / 8) * 2; // 真实的图片byte大小
      return Math.floor(fileLength); // 向下取整
    }
    const strLength = base64.length;
    const fileLength = strLength - (strLength / 8) * 2;
    return Math.floor(fileLength); // 向下取整
  }
  return 0;
};

export const base64ToFile = (base64) => {
  return fetch(base64)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], 'file', { type: blob.type });
    });
};

export const getDisbleBeforeDateOption = () => {
  const rangeHours = (start, end, value, chooseday, day) => {
    const result = [];
    if (chooseday === day) {
      for (let i = start; i < end; i++) {
        if (i < value) {
          result.push(i);
        }
      }
    }
    return result;
  };
  const rangeMinutes = (start, end, value, chooseday, day, chooseh, h) => {
    const result = [];
    if (chooseday === day && chooseh === h) {
      for (let i = start; i < end; i++) {
        if (i < value) {
          result.push(i);
        }
      }
    }
    return result;
  };
  const rangeSeconds = (start, end, value, chooseday, day, chooseh, h, choosem, m) => {
    const result = [];
    if (chooseday === day && chooseh === h && choosem === m) {
      for (let i = start; i < end; i++) {
        if (i < value) {
          result.push(i);
        }
      }
    }
    return result;
  };
  const disabledDate = (current) => {
    const time = moment().endOf('day').subtract(1, 'days');
    return current && current < time;
  };
  const disabledDateTime = (date) => {
    // 选中的时间
    const choosetime = moment(date).format('HH-mm-ss');
    const chooseday = parseInt(moment(date).format('YYYY-MM-DD').split('-')[2], 10);
    const chooseh = parseInt(choosetime.split('-')[0], 10);
    const choosem = parseInt(choosetime.split('-')[1], 10);
    // 当前时间
    const nowtime = moment().format('HH-mm-ss');
    const day = parseInt(moment().format('YYYY-MM-DD').split('-')[2], 10);
    const h = parseInt(nowtime.split('-')[0], 10);
    const m = parseInt(nowtime.split('-')[1], 10);
    const s = parseInt(nowtime.split('-')[2], 10);
    return {
      disabledHours: () => rangeHours(0, 24, h, chooseday, day),
      disabledMinutes: () => rangeMinutes(0, 60, m, chooseday, day, chooseh, h),
      disabledSeconds: () => rangeSeconds(0, 60, s, chooseday, day, chooseh, h, choosem, m),
    };
  };

  return {
    disabledDate,
    disabledDateTime,
  };
};
export const getDisbleAfterDateOption = (targetTime) => {
  const rangeHours = (start, end, value, chooseday, day) => {
    const result = [];
    if (chooseday === day) {
      for (let i = start; i < end; i++) {
        if (i > value) {
          result.push(i);
        }
      }
    }
    return result;
  };
  const rangeMinutes = (start, end, value, chooseday, day, chooseh, h) => {
    const result = [];
    if (chooseday === day && chooseh === h) {
      for (let i = start; i < end; i++) {
        if (i > value) {
          result.push(i);
        }
      }
    }
    return result;
  };
  const rangeSeconds = (start, end, value, chooseday, day, chooseh, h, choosem, m) => {
    const result = [];
    if (chooseday === day && chooseh === h && choosem === m) {
      for (let i = start; i < end; i++) {
        if (i > value) {
          result.push(i);
        }
      }
    }
    return result;
  };
  const disabledDate = (current) => {
    const time = moment(targetTime).endOf('day');
    return current && current > time;
  };
  const disabledDateTime = (date) => {
    // 选中的时间
    const choosetime = moment(date).format('HH-mm-ss');
    const chooseday = parseInt(moment(date).format('YYYY-MM-DD').split('-')[2], 10);
    const chooseh = parseInt(choosetime.split('-')[0], 10);
    const choosem = parseInt(choosetime.split('-')[1], 10);
    // 当前时间
    const nowtime = moment(targetTime).format('HH-mm-ss');
    const day = parseInt(moment(targetTime).format('YYYY-MM-DD').split('-')[2], 10);
    const h = parseInt(nowtime.split('-')[0], 10);
    const m = parseInt(nowtime.split('-')[1], 10);
    const s = parseInt(nowtime.split('-')[2], 10);
    return {
      disabledHours: () => rangeHours(0, 24, h, chooseday, day),
      disabledMinutes: () => rangeMinutes(0, 60, m, chooseday, day, chooseh, h),
      disabledSeconds: () => rangeSeconds(0, 60, s, chooseday, day, chooseh, h, choosem, m),
    };
  };

  return {
    disabledDate,
    disabledDateTime,
  };
};

export const getIsSystemId = (id) => id === '00000000-0000-0000-0000-000000000000';
export const toBMPPage = (path, target) => {
  let domain;
  if (document.domain?.includes('localhost')) domain = 'http://localhost:8888';
  else if (document.domain?.includes('dev')) domain = 'https://bmp-public-dev.wukongedu.net';
  else if (document.domain?.includes('test')) domain = 'https://bmp-public-test.wukongedu.net';
  else domain = 'https://bmp-public.wukongedu.net';

  window.open(domain + path, target || '_blank');
  return true;
};
